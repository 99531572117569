import React from 'react';
import PropTypes from 'prop-types';

import styles from './List.module.scss';

const List = ({ children }) => (
  <div className={styles.container}>
    <ul className={styles.list}>{children}</ul>
  </div>
);

List.propTypes = {
  children: PropTypes.node,
};

export default List;
