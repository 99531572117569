import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RichText } from 'prismic-reactjs';

import { contentSerializer, linkResolver } from '../../../utils';

import styles from './Timeline.module.scss';

const Timeline = ({ title, description, date, link }) => (
  <li className={styles.container}>
    <p className={styles.date}>{moment(date).format('MMM DD, YYYY')}</p>
    <h2 className={styles.title}>
      {link ? (
        <a
          href={linkResolver(link)}
          target={link._linkType === 'Link.web' ? '_blank' : undefined}
          rel={link._linkType === 'Link.web' ? 'noopener noreferrer' : undefined}>
          {title}
        </a>
      ) : (
        title
      )}
    </h2>
    <p className={styles.description}>
      <RichText render={description} htmlSerializer={contentSerializer} />
    </p>
  </li>
);

Timeline.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.object,
};

export default Timeline;
