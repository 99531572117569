import React from 'react';

import styles from './404.module.scss';
import { Link } from 'gatsby';

const FourOhFour = () => (
  <div className={styles.body}>
    <div className={styles.content}>
      <span className={styles.overline}>404: Page Not Found</span>
      <h1 className={styles.title}>
        The URL{' '}
        {typeof location !== 'undefined' && location.pathname && (
          <span className={styles.route}>{location.pathname}</span>
        )}{' '}
        does not exist. Try the <Link to={'/'}>homepage</Link> instead.
      </h1>
    </div>
  </div>
);

export default FourOhFour;
