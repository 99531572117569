import React from 'react';

import SEO from '../components/SEO';
import FourOhFour from '../components/404';
import Footer from '../components/Footer';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <FourOhFour />
    <Footer />
  </>
);

export default NotFoundPage;
